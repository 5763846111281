import React, { useState, useContext } from "react"
import { createPopup } from "@typeform/embed"
import "@typeform/embed/build/css/popup.css"

const RegistrationContext = React.createContext()

const TypeformRegistrationContext = ({ children }) => {
  const [registerButton, setRegisterButton] = useState({
    state: true,
    buttonText: "Apply now",
  })

  const typeformOptions = {
    size: 100,
    hideFooter: true,
    //enableSandbox: true,
    onSubmit: event => {
      setTimeout(() => close(), 1500)
      if (event && event.responseId && event.responseId !== "") {
        setRegisterButton({
          state: false,
          buttonText: "Successfully Registered",
        })
      } else {
        setRegisterButton({
          state: true,
          buttonText: "Try Again",
          errorMessage:
            "Unfortunately something went wrong in the process of submitting your information. Please try again or get in touch with Bastian <bastian@pnptc.com>.",
        })
      }
    },
  }

  const { toggle, close } = createPopup("XWvYHYza", typeformOptions)

  const value = { registerButton, toggle }

  return (
    <RegistrationContext.Provider value={value}>
      {children}
    </RegistrationContext.Provider>
  )
}

const useRegistration = () => {
  const context = useContext(RegistrationContext)

  if (context === undefined) {
    throw new Error(
      "useRegistration must be used within a TypeformRegistrationProvider"
    )
  }
  return context
}

export { TypeformRegistrationContext, useRegistration }
